var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"visible":_vm.isDialog,"append-to-body":"","width":_vm.width + '%',"before-close":_vm.cancellationFn},on:{"update:visible":function($event){_vm.isDialog=$event}}},[_c('div',{staticStyle:{"color":"#409eff"},attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"business-center"},[_c('div',{staticClass:"before-change"},[_vm._v("更改前")]),_c('div',{staticClass:"after-the-alteration"},[_vm._v("更改后")])])]),_c('div',{staticClass:"business-center"},[(_vm.form.oldData)?_c('div',{staticClass:"business-left"},[_c('el-descriptions',{attrs:{"title":"商务基本信息","direction":"vertical","column":3,"border":""}},[_c('el-descriptions-item',{attrs:{"span":3,"contentStyle":_vm.form.changeFieldList.includes('businessName')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 商务名称 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.businessName)+" ")])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('initiatorId')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 商务创建人 ")]),_c('div',{staticClass:"input_style"},[_c('div',[_vm._v(_vm._s(_vm.getLinshi_initiator(_vm.form.oldData.initiatorId)))])])],2),_c('el-descriptions-item',{attrs:{"label":"信息提供人","contentStyle":_vm.form.changeFieldList.includes('provideId') ? { 'background-color': '#bff0de' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 信息提供人 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getLinshi_initiator(_vm.form.oldData.provideId))+" ")])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('companyType')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v("项目承接公司 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.oldData.companyType,_vm.dictData.companyType))+" ")])],2),_c('el-descriptions-item',{attrs:{"span":3,"label":"业主单位","contentStyle":_vm.form.changeFieldList.includes('ownerUnit') ? { 'background-color': '#bff0de' } : ''}},[_c('div',[_vm._v(_vm._s(_vm.form.oldData.ownerUnit))])]),_c('el-descriptions-item',{attrs:{"label":"经办人","contentStyle":_vm.form.changeFieldList.includes('contacts') ? { 'background-color': '#bff0de' } : ''}},[_c('div',[_vm._v(_vm._s(_vm.form.oldData.contacts))])]),_c('el-descriptions-item',{attrs:{"label":"经办人职务","contentStyle":_vm.form.changeFieldList.includes('post') ? { 'background-color': '#bff0de' } : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.post)+" ")])]),_c('el-descriptions-item',{attrs:{"label":"经办人电话","contentStyle":_vm.form.changeFieldList.includes('contactsPhone')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.contactsPhone)+" ")])]),_c('el-descriptions-item',{attrs:{"label":"决策人","contentStyle":_vm.form.changeFieldList.includes('decisionMaker')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.decisionMaker)+" ")])]),_c('el-descriptions-item',{attrs:{"label":"决策人职务","contentStyle":_vm.form.changeFieldList.includes('decisionMakerPost')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.decisionMakerPost)+" ")])]),_c('el-descriptions-item',{attrs:{"label":"决策人电话","contentStyle":_vm.form.changeFieldList.includes('decisionMakerContactsPhone')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.decisionMakerContactsPhone)+" ")])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('bidWay') ? { 'background-color': '#bff0de' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 招标方式 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.oldData.bidWay,_vm.dictData.bidwayOptions))+" ")])],2),_c('el-descriptions-item',{attrs:{"label":"招投标费用(元)","contentStyle":_vm.form.changeFieldList.includes('tenderFee') ? { 'background-color': '#bff0de' } : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.oldData.tenderFee))+" ")])]),_c('el-descriptions-item',{attrs:{"label":"招投标费用大写"}},[_vm._v(_vm._s(_vm._f("numberParseChina")(_vm.form.oldData.tenderFee))+" ")]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('predictMoney')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 预计合同金额(元) ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.oldData.predictMoney))+" ")])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('ourContractAmount')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 我方合同金额(元) ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.oldData.ourContractAmount))+" ")])],2)],1),_c('el-descriptions',{attrs:{"direction":"vertical","column":1,"border":""}},[_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('content') ? { 'background-color': '#bff0de' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 商务说明 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.content)+" ")])],2)],1),_c('el-descriptions',{staticStyle:{"margin-top":"10px"},attrs:{"title":"税率","direction":"vertical","column":5,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"我方合同金额(元)","span":1,"contentStyle":_vm.form.changeFieldList.includes('ourContractAmount')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(_vm._s(_vm._f("applyAmount")(_vm.form.oldData.ourContractAmount)))])]),_c('el-descriptions-item',{attrs:{"span":1,"contentStyle":_vm.form.changeFieldList.includes('taxType') ? { 'background-color': '#bff0de' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 税费类型 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.oldData.taxType,_vm.dictData.taxType))+" ")])],2),_c('el-descriptions-item',{attrs:{"label":"增值税类型","span":2,"contentStyle":_vm.form.changeFieldList.includes('vatType') ? { 'background-color': '#bff0de' } : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("transitionType")(_vm.form.oldData.vatType,_vm.zzsList))+" ")])]),_c('el-descriptions-item',{attrs:{"label":"增值税类型详细","span":1,"contentStyle":_vm.form.changeFieldList.includes('vatData') ? { 'background-color': '#bff0de' } : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("transitionData")(_vm.form.oldData.vatData,_vm.dictData.oldVatData))+" ")])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('vatRate') ? { 'background-color': '#bff0de' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 增值税税率(%) ")]),_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.vatRate)+" ")])],2),_c('el-descriptions-item',{attrs:{"label":"增值税","contentStyle":_vm.form.changeFieldList.includes('actualVat') ? { 'background-color': '#bff0de' } : ''}},[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.oldData.actualVat))+" （元） ")]),_c('el-descriptions-item',{attrs:{"label":"附加税税率"}},[_vm._v("12%")]),_c('el-descriptions-item',{attrs:{"label":"附加税","contentStyle":_vm.form.changeFieldList.includes('actualAdditionalTax')
                ? { 'background-color': '#bff0de' }
                : ''}},[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.oldData.actualAdditionalTax))+" （元） ")]),_c('el-descriptions-item',{attrs:{"label":"印花税税率"}},[_vm._v("0.03%")]),_c('el-descriptions-item',{attrs:{"label":"印花税","contentStyle":_vm.form.changeFieldList.includes('actualStampTax')
                ? { 'background-color': '#bff0de' }
                : ''}},[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.oldData.actualStampTax))+" （元） ")]),_c('el-descriptions-item',{attrs:{"label":"税费","contentStyle":_vm.form.changeFieldList.includes('taxesFee') ? { 'background-color': '#bff0de' } : ''}},[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.oldData.taxesFee))+" （元） ")])],1),_c('el-descriptions',{attrs:{"title":"业务类型","direction":"vertical","column":3,"border":""}},[_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('businessType')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 商务类型 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.oldData.businessType,_vm.dictData.businessType))+" ")])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('businessLines')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 业务种类 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.oldData.businessLines,_vm.dictData.businessLines))+" ")])],2),_c('el-descriptions-item',{attrs:{"label":"项目种类","contentStyle":_vm.form.changeFieldList.includes('projectLines')
                ? { 'background-color': '#bff0de' }
                : ''}},[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.oldData.projectLines,_vm.dictData.projectLines))+" ")]),_c('el-descriptions-item',{attrs:{"span":3,"contentStyle":_vm.form.changeFieldList.includes('projectSource')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 项目来源 ")]),_c('div',[_c('el-radio-group',{model:{value:(_vm.form.oldData.projectSource),callback:function ($$v) {_vm.$set(_vm.form.oldData, "projectSource", $$v)},expression:"form.oldData.projectSource"}},[_c('div',{staticClass:"radio-group"},[_c('div',{staticClass:"radio-label"},[_vm._v("生产类型:")]),_c('div',{staticClass:"radio-item"},_vm._l((_vm.dictData.produceList),function(item){return _c('el-radio',{key:item.id,attrs:{"disabled":true,"label":item.dictVal}},[_vm._v(_vm._s(item.dictName))])}),1)]),_c('div',{staticClass:"radio-group"},[_c('div',{staticClass:"radio-label"},[_vm._v("非生产类型:")]),_c('div',{staticClass:"radio-item"},_vm._l((_vm.dictData.unProduceList),function(item){return _c('el-radio',{key:item.id,attrs:{"disabled":true,"label":item.dictVal}},[_vm._v(_vm._s(item.dictName))])}),1)])])],1)],2),(_vm.form.oldData.projectSource == 'zi_zhi_he_zuo')?_c('el-descriptions-item',{attrs:{"label":"合作费比例(%)","contentStyle":_vm.form.changeFieldList.includes('collaborationCostsRatio')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(_vm._s(_vm.form.oldData.collaborationCostsRatio))])]):_vm._e(),_c('el-descriptions-item',{attrs:{"label":"外部合作者","span":2,"contentStyle":_vm.form.changeFieldList.includes('externalPartnere')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_c('el-radio',{attrs:{"disabled":true,"label":"NOT"},model:{value:(_vm.form.oldData.externalPartnere),callback:function ($$v) {_vm.$set(_vm.form.oldData, "externalPartnere", $$v)},expression:"form.oldData.externalPartnere"}},[_vm._v("无外部合作者")]),_c('el-radio',{attrs:{"disabled":true,"label":"HAVE"},model:{value:(_vm.form.oldData.externalPartnere),callback:function ($$v) {_vm.$set(_vm.form.oldData, "externalPartnere", $$v)},expression:"form.oldData.externalPartnere"}},[_vm._v("有外部合作者")])],1)])],1),(_vm.form.oldData.externalPartnere == 'HAVE')?_c('el-descriptions',{staticStyle:{"margin-top":"10px"},attrs:{"title":"外部协作信息","direction":"vertical","column":2,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"费用结算方式","contentStyle":_vm.form.changeFieldList.includes('contentCooperationType')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.contentCooperationType == 1 ? '按具体金额' : '按比例')+" ")])]),(_vm.form.oldData.contentCooperationType == 1)?_c('el-descriptions-item',{attrs:{"label":"预计外部协作费用金额(元)","contentStyle":_vm.form.changeFieldList.includes('contentCooperationMoney')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 预计外部协作费用金额(元) ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.oldData.contentCooperationMoney))+" ")])],2):_vm._e(),(_vm.form.oldData.contentCooperationType == 2)?_c('el-descriptions-item',{attrs:{"label":"预计外部协作费用比例(%)","contentStyle":_vm.form.changeFieldList.includes('contentCooperationRatio')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.oldData.contentCooperationRatio)+" ")])]):_vm._e(),_c('el-descriptions-item',{attrs:{"label":"外部协作内容","span":2,"contentStyle":_vm.form.changeFieldList.includes('contentCooperationContent')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(_vm._s(_vm.form.oldData.contentCooperationContent))])])],1):_vm._e(),_c('el-descriptions',{staticStyle:{"margin-top":"10px"},attrs:{"title":"角色分配","direction":"vertical","column":4,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"建议商务负责人","contentStyle":_vm.form.changeFieldList.includes('suggestionPrincipalUserId')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.getLinshi_initiator(_vm.form.oldData.suggestionPrincipalUserId))+" ")])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('principalUserId')
                ? { 'background-color': '#bff0de' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 实际商务负责人 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getLinshi_initiator(_vm.form.oldData.principalUserId))+" ")])],2)],1)],1):_vm._e(),(_vm.form.newData)?_c('div',{staticClass:"business-right"},[_c('el-descriptions',{attrs:{"title":"商务基本信息","direction":"vertical","column":3,"border":""}},[_c('el-descriptions-item',{attrs:{"span":3,"contentStyle":_vm.form.changeFieldList.includes('businessName')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 商务名称 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.businessName)+" ")])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('initiatorId')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 商务创建人 ")]),_c('div',{staticClass:"input_style"},[_c('div',[_vm._v(_vm._s(_vm.getLinshi_initiator(_vm.form.newData.initiatorId)))])])],2),_c('el-descriptions-item',{attrs:{"label":"信息提供人","contentStyle":_vm.form.changeFieldList.includes('provideId') ? { 'background-color': '#eef9b2' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 信息提供人 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getLinshi_initiator(_vm.form.newData.provideId))+" ")])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('companyType')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v("项目承接公司 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.newData.companyType,_vm.dictData.companyType))+" ")])],2),_c('el-descriptions-item',{attrs:{"span":3,"label":"业主单位","contentStyle":_vm.form.changeFieldList.includes('ownerUnit') ? { 'background-color': '#eef9b2' } : ''}},[_c('div',[_vm._v(_vm._s(_vm.form.newData.ownerUnit))])]),_c('el-descriptions-item',{attrs:{"label":"经办人","contentStyle":_vm.form.changeFieldList.includes('contacts') ? { 'background-color': '#eef9b2' } : ''}},[_c('div',[_vm._v(_vm._s(_vm.form.newData.contacts))])]),_c('el-descriptions-item',{attrs:{"label":"经办人职务","contentStyle":_vm.form.changeFieldList.includes('post') ? { 'background-color': '#eef9b2' } : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.post)+" ")])]),_c('el-descriptions-item',{attrs:{"label":"经办人电话","contentStyle":_vm.form.changeFieldList.includes('contactsPhone')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.contactsPhone)+" ")])]),_c('el-descriptions-item',{attrs:{"label":"决策人","contentStyle":_vm.form.changeFieldList.includes('decisionMaker')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.decisionMaker)+" ")])]),_c('el-descriptions-item',{attrs:{"label":"决策人职务","contentStyle":_vm.form.changeFieldList.includes('decisionMakerPost')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.decisionMakerPost)+" ")])]),_c('el-descriptions-item',{attrs:{"label":"决策人电话","contentStyle":_vm.form.changeFieldList.includes('decisionMakerContactsPhone')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.decisionMakerContactsPhone)+" ")])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('bidWay') ? { 'background-color': '#eef9b2' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 招标方式 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.newData.bidWay,_vm.dictData.bidwayOptions))+" ")])],2),_c('el-descriptions-item',{attrs:{"label":"招投标费用(元)","contentStyle":_vm.form.changeFieldList.includes('tenderFee') ? { 'background-color': '#eef9b2' } : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.newData.tenderFee))+" ")])]),_c('el-descriptions-item',{attrs:{"label":"招投标费用大写"}},[_vm._v(_vm._s(_vm._f("numberParseChina")(_vm.form.newData.tenderFee))+" ")]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('predictMoney')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 预计合同金额(元) ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.newData.predictMoney))+" ")])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('ourContractAmount')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 我方合同金额(元) ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.newData.ourContractAmount))+" ")])],2)],1),_c('el-descriptions',{attrs:{"direction":"vertical","column":1,"border":""}},[_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('content') ? { 'background-color': '#eef9b2' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 商务说明 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.content)+" ")])],2)],1),_c('el-descriptions',{staticStyle:{"margin-top":"10px"},attrs:{"title":"税率","direction":"vertical","column":5,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"我方合同金额(元)","span":1,"contentStyle":_vm.form.changeFieldList.includes('ourContractAmount')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(_vm._s(_vm._f("applyAmount")(_vm.form.newData.ourContractAmount)))])]),_c('el-descriptions-item',{attrs:{"span":1,"contentStyle":_vm.form.changeFieldList.includes('taxType') ? { 'background-color': '#eef9b2' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 税费类型 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.newData.taxType,_vm.dictData.taxType))+" ")])],2),_c('el-descriptions-item',{attrs:{"label":"增值税类型","span":2,"contentStyle":_vm.form.changeFieldList.includes('vatType') ? { 'background-color': '#eef9b2' } : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("transitionType")(_vm.form.newData.vatType,_vm.zzsList))+" ")])]),_c('el-descriptions-item',{attrs:{"label":"增值税类型详细","span":1,"contentStyle":_vm.form.changeFieldList.includes('vatData') ? { 'background-color': '#eef9b2' } : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("transitionData")(_vm.form.newData.vatData,_vm.dictData.newVatData))+" ")])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('vatRate') ? { 'background-color': '#eef9b2' } : ''}},[_c('template',{slot:"label"},[_vm._v(" 增值税税率(%) ")]),_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.vatRate)+" ")])],2),_c('el-descriptions-item',{attrs:{"label":"增值税","contentStyle":_vm.form.changeFieldList.includes('actualVat') ? { 'background-color': '#eef9b2' } : ''}},[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.newData.actualVat))+" （元） ")]),_c('el-descriptions-item',{attrs:{"label":"附加税税率"}},[_vm._v("12%")]),_c('el-descriptions-item',{attrs:{"label":"附加税","contentStyle":_vm.form.changeFieldList.includes('actualAdditionalTax')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.newData.actualAdditionalTax))+" （元） ")]),_c('el-descriptions-item',{attrs:{"label":"印花税税率"}},[_vm._v("0.03%")]),_c('el-descriptions-item',{attrs:{"label":"印花税","contentStyle":_vm.form.changeFieldList.includes('actualStampTax')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.newData.actualStampTax))+" （元） ")]),_c('el-descriptions-item',{attrs:{"label":"税费","contentStyle":_vm.form.changeFieldList.includes('taxesFee') ? { 'background-color': '#eef9b2' } : ''}},[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.newData.taxesFee))+" （元） ")])],1),_c('el-descriptions',{attrs:{"title":"业务类型","direction":"vertical","column":3,"border":""}},[_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('businessType')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 商务类型 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.newData.businessType,_vm.dictData.businessType))+" ")])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('businessLines')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 业务种类 ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.newData.businessLines,_vm.dictData.businessLines))+" ")])],2),_c('el-descriptions-item',{attrs:{"label":"项目种类","contentStyle":_vm.form.changeFieldList.includes('projectLines')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.form.newData.projectLines,_vm.dictData.projectLines))+" ")]),_c('el-descriptions-item',{attrs:{"span":3,"contentStyle":_vm.form.changeFieldList.includes('projectSource')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 项目来源 ")]),_c('div',[_c('el-radio-group',{model:{value:(_vm.form.newData.projectSource),callback:function ($$v) {_vm.$set(_vm.form.newData, "projectSource", $$v)},expression:"form.newData.projectSource"}},[_c('div',{staticClass:"radio-group"},[_c('div',{staticClass:"radio-label"},[_vm._v("生产类型:")]),_c('div',{staticClass:"radio-item"},_vm._l((_vm.dictData.produceList),function(item){return _c('el-radio',{key:item.id,attrs:{"disabled":true,"label":item.dictVal}},[_vm._v(_vm._s(item.dictName))])}),1)]),_c('div',{staticClass:"radio-group"},[_c('div',{staticClass:"radio-label"},[_vm._v("非生产类型:")]),_c('div',{staticClass:"radio-item"},_vm._l((_vm.dictData.unProduceList),function(item){return _c('el-radio',{key:item.id,attrs:{"disabled":true,"label":item.dictVal}},[_vm._v(_vm._s(item.dictName))])}),1)])])],1)],2),(_vm.form.newData.projectSource == 'zi_zhi_he_zuo')?_c('el-descriptions-item',{attrs:{"label":"合作费比例(%)","contentStyle":_vm.form.changeFieldList.includes('collaborationCostsRatio')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(_vm._s(_vm.form.newData.collaborationCostsRatio))])]):_vm._e(),_c('el-descriptions-item',{attrs:{"label":"外部合作者","span":2,"contentStyle":_vm.form.changeFieldList.includes('externalPartnere')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_c('el-radio',{attrs:{"disabled":true,"label":"NOT"},model:{value:(_vm.form.newData.externalPartnere),callback:function ($$v) {_vm.$set(_vm.form.newData, "externalPartnere", $$v)},expression:"form.newData.externalPartnere"}},[_vm._v("无外部合作者")]),_c('el-radio',{attrs:{"disabled":true,"label":"HAVE"},model:{value:(_vm.form.newData.externalPartnere),callback:function ($$v) {_vm.$set(_vm.form.newData, "externalPartnere", $$v)},expression:"form.newData.externalPartnere"}},[_vm._v("有外部合作者")])],1)])],1),(_vm.form.newData.externalPartnere == 'HAVE')?_c('el-descriptions',{staticStyle:{"margin-top":"10px"},attrs:{"title":"外部协作信息","direction":"vertical","column":2,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"费用结算方式","contentStyle":_vm.form.changeFieldList.includes('contentCooperationType')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.contentCooperationType == 1 ? '按具体金额' : '按比例')+" ")])]),(_vm.form.newData.contentCooperationType == 1)?_c('el-descriptions-item',{attrs:{"label":"预计外部协作费用金额(元)","contentStyle":_vm.form.changeFieldList.includes('contentCooperationMoney')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 预计外部协作费用金额(元) ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("applyAmount")(_vm.form.newData.contentCooperationMoney))+" ")])],2):_vm._e(),(_vm.form.newData.contentCooperationType == 2)?_c('el-descriptions-item',{attrs:{"label":"预计外部协作费用比例(%)","contentStyle":_vm.form.changeFieldList.includes('contentCooperationRatio')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.form.newData.contentCooperationRatio)+" ")])]):_vm._e(),_c('el-descriptions-item',{attrs:{"label":"外部协作内容","span":2,"contentStyle":_vm.form.changeFieldList.includes('contentCooperationContent')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(_vm._s(_vm.form.newData.contentCooperationContent))])])],1):_vm._e(),_c('el-descriptions',{staticStyle:{"margin-top":"10px"},attrs:{"title":"角色分配","direction":"vertical","column":4,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"建议商务负责人","contentStyle":_vm.form.changeFieldList.includes('suggestionPrincipalUserId')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('div',[_vm._v(" "+_vm._s(_vm.getLinshi_initiator(_vm.form.newData.suggestionPrincipalUserId))+" ")])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('principalUserId')
                ? { 'background-color': '#eef9b2' }
                : ''}},[_c('template',{slot:"label"},[_vm._v(" 实际商务负责人 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getLinshi_initiator(_vm.form.newData.principalUserId))+" ")])],2)],1)],1):_vm._e()]),_c('span',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"slot":"footer","name":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"min-width":"120px","margin":"0 25px"},attrs:{"type":"primary","plain":"","loading":_vm.loading},on:{"click":_vm.cancellationFn}},[_vm._v("返 回")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }